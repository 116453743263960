import Vue from 'vue'

import 'normalize.css/normalize.css' // a modern alternative to CSS resets
import Element, {Message} from 'element-ui'
import './directive/index'
import App from './App'
import store from './store'
import router from './router'

import './modules'
import './plugins'
import './icons' // icon
import './permission' // permission control
import './utils/error-log' // error log
import util from './plugins/util'
import { dictText, dictData } from './plugins/dictionary'
import { asyn } from './plugins/promise'
import { Loading } from 'element-ui'
import moment from 'moment' // 导入文件

import { limitInputNumber } from './utils/index'

Vue.use(Element)

Vue.config.productionTip = false

Vue.prototype.util = util
Vue.prototype.dictText = dictText
Vue.prototype.dictData = dictData
Vue.prototype.asyn = asyn
Vue.prototype.$loading = Loading
Vue.prototype.Message = Message
Vue.prototype.$moment = moment
Vue.prototype.$store = store
Vue.prototype.$limitInputNumber = limitInputNumber

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})
