import request from '@/utils/request'

// 修改用户信息
export function editUserProfile(data) {
  return request({
    url: '/system/user/profile',
    method: 'put',
    data
  })
}

// 查询小程序用户表
export function getUserList(params) {
  return request({
    url: '/api/v1/appUser/getPage',
    method: 'get',
    params
  })
}

// 导出用户
export function exportUser(data) {
  return request({
    url: '/api/v1/appUser/export',
    method: 'post',
    data,
    timeout: 120000,
    responseType: 'blob'
  })
}

// 查询用户关注艺术家
export function getUserArtistFav(params) {
  return request({
    url: '/api/v1/artistFav/getPage',
    method: 'get',
    params
  })
}

// 查询用户收藏
export function getUserProductFav(params) {
  return request({
    url: '/api/v1/userProductFav/getPage',
    method: 'get',
    params
  })
}
